$blue: hsl(211, 66%, 35%);
$blue-no-sat: hsl(211, 0%, 35%);
$bright-blue: hsl(211, 64%, 70%);

$vortex-dark-orange: rgb(200, 123, 40);
$vortex-orange: rgb(217 143 64);
$vortex-bright-orange: rgb(224 163 98);

$mo2-dark-blue: #3382a1;
$mo2-blue: #3ebcee;

%input-base {
    width: calc(100% - 40px);
    max-width: -webkit-fill-available;

    background: #222;
    color: #e2e2e2;

    resize: none;

    font-family: inherit;
    font-size: 0.9em;

    padding: 6px 9px;
    border: 4px solid #333;
    border-color: #333;
    border-radius: 8px;
    outline: none;

    &:active, &:focus, &:focus-within {
        border-color: $blue;
        color: #fff;
    }

    transition: border-color 0.3s cubic-bezier(0, 0.92, 0.58, 1), width 0.1s cubic-bezier(0, 0.92, 0.58, 1);

    appearance: textfield;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
    }
}

%label-style {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
    max-width: 100%;
    max-width: -webkit-fill-available;
}

@use './vars' as *;

html, body {
    overflow: hidden;
    background: #141414;
    color: #ccc;

    color-scheme: dark;

    width: 100vw;
    height: 100vh;
    margin: 0;

    line-height: 1.3;
    letter-spacing: 0.15px;
    word-spacing: 0.3px;
}

h1 {
    color: #fff;
}

h2 {
    color: #eee;
}

h3 {
    color: #ddd;
}

a {
    &, * { color: #519aff !important; }
    text-decoration: none;
}

code {
    font-family: var(--code-font);
    color: #ddd;
    padding: 1px 4px;
    background: #282828;
    border-radius: 5px;
}

label {
    @extend %label-style
}
